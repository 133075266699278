import { ChevronRight, MessageCircle, CheckCircle } from "lucide-react";
import React, { useState, useEffect } from "react";
import Sidebar from "./sidemenu";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { sendSupportMessage, getFAQs } from "../lib/apiCalls";
import ReactMarkdown from "react-markdown";

const FAQItem = ({ question, isActive, onClick }) => (
  <div
    className={`p-4 border-b cursor-pointer ${
      isActive ? "bg-purple-100" : "hover:bg-gray-100"
    }`}
    onClick={onClick}
  >
    <h3 className="text-lg font-medium">{question}</h3>
  </div>
);

const AnswerSection = ({ answer }) => (
  <div className="border border-[#F7F7F7] bg-white rounded-lg">
    <h2 className="text-xl p-6 border-t border-transparent rounded-t-lg bg-purple-50 font-bold mb-4">
      {answer.question}
    </h2>
    <div className="text-gray-700 p-6 prose text-[18px] max-w-none">
      <ReactMarkdown>{answer.answer}</ReactMarkdown>
    </div>
  </div>
);

export default function HelpCentre() {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [isLoadingFAQs, setIsLoadingFAQs] = useState(true);

  useEffect(() => {
    const fetchFAQs = async () => {
      setIsLoadingFAQs(true);
      try {
        const response = await getFAQs();
        if (response.success) {
          setFaqs(response.faqs);
          if (response.faqs.length > 0) {
            setActiveQuestion(response.faqs[0].question);
          }
        } else {
          setError("Failed to fetch FAQs. Please try again.");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          window.location.href = "/login";
        }
        setError("An error occurred while fetching FAQs. Please try again.");
      } finally {
        setIsLoadingFAQs(false);
      }
    };

    fetchFAQs();
  }, []);

  const handleSendMessage = async () => {
    if (message.trim() !== "") {
      setIsLoading(true);
      setError(null);
      try {
        const response = await sendSupportMessage(message);
        if (response.success) {
          setMessageSent(true);
          setMessage("");
        } else {
          setError("Failed to send message. Please try again.");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          window.location.href = "/login";
        }
        setError("An error occurred. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleNewMessage = () => {
    setMessageSent(false);
    setError(null);
  };

  // if (isLoadingFAQs) {
  //   return (
  //     <div className="flex min-h-screen bg-gray-50">
  //       <Sidebar />
  //       <div className=" p-8 ml-[380px] flex w-full gap-12 items-center justify-center">

  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="flex min-h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 p-8 ml-[380px]">
        <h1 className="text-3xl font-bold mb-8 font-['Cormorant_Garamond',serif]">
          Help Centre
        </h1>
        <div className="flex gap-8">
          <div className="flex-1 w-[90%]">
            <h2 className="text-2xl font-semibold mb-4 font-['Cormorant_Garamond',serif]">
              Frequently Asked Questions
            </h2>
            <p className="text-gray-600 mb-6">
              Here are some common FAQ questions and potential answers
            </p>
            {isLoadingFAQs ? (
              <div className="flex items-center justify-between  rounded-md animate-pulse h-1/2 w-full">
                <div className="flex flex-col w-full h-full justify-between flex-grow p-4">
                  <div className="h-10 bg-gray-200 rounded-md animate-pulse w-full px-4"></div>
                  <div className="h-10 bg-gray-200 rounded-md animate-pulse w-full px-4"></div>
                  <div className="h-10 bg-gray-200 rounded-md animate-pulse w-full px-4"></div>
                  <div className="h-10 bg-gray-200 rounded-md animate-pulse w-full px-4"></div>
                  <div className="h-10 bg-gray-200 rounded-md animate-pulse w-full px-4"></div>
                  <div className="h-10 bg-gray-200 rounded-md animate-pulse w-full px-4"></div>
                </div>
              </div>
            ) : (
              <div className="bg-white rounded-lg">
                {faqs.map((faq, index) => (
                  <FAQItem
                    key={index}
                    question={faq.question}
                    isActive={activeQuestion === faq.question}
                    onClick={() => setActiveQuestion(faq.question)}
                  />
                ))}
              </div>
            )}
            <div className="mt-12 flex justify-center py-6 rounded-lg items-center bg-white border border-[#F7F7F7]">
              <div className="flex w-9/12 flex-col items-center mb-4">
                {!messageSent ? (
                  <>
                    <div className="flex flex-col w-[50%] items-center mb-4">
                      <MessageCircle className="text-blue-600 mr-2" size={48} />
                      <h3 className="text-lg mt-3 font-semibold">
                        Send us a message!
                      </h3>
                    </div>
                    <p className="text-center">
                      Got any issue? Send us a mail <br />
                      and we'll get back to you
                    </p>
                    <div className="relative mt-12 w-[95%]">
                      <Input
                        placeholder="Your message here..."
                        className="pr-10 rounded-lg"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        disabled={isLoading}
                      />
                      <Button
                        className="absolute right-0 top-0 bottom-0 rounded-full"
                        variant="ghost"
                        onClick={handleSendMessage}
                        disabled={isLoading}
                      >
                        <ChevronRight size={20} className="text-blue-600" />
                      </Button>
                    </div>
                    {error && (
                      <p className="text-red-500 text-sm mt-2">{error}</p>
                    )}
                  </>
                ) : (
                  <>
                    <div className="flex flex-col w-[50%] items-center mb-4">
                      <CheckCircle className="text-green-600 mr-2" size={48} />
                      <h3 className="text-lg mt-3 font-semibold">
                        Message Sent!
                      </h3>
                    </div>
                    <p className="text-center">
                      Thanks for your message! <br />
                      We will get back to you soon.
                    </p>
                    <Button
                      className="mt-12 rounded-full bg-blue-600 text-white hover:bg-blue-700"
                      onClick={handleNewMessage}
                    >
                      Send new Message
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 mr-10">
            {isLoadingFAQs ? (
              <div className="flex-col justify-between p-6 rounded-md animate-pulse w-full h-[60%]">
                <div className="h-12 bg-gray-200 rounded-md animate-pulse mb-2 w-1/3"></div>
                <div className="h-20 bg-gray-200 rounded-md animate-pulse w-full mb-7 px-4"></div>

                <div className="h-12 bg-gray-200 rounded-md animate-pulse mb-2 w-1/3"></div>
                <div className="h-20 bg-gray-200 rounded-md animate-pulse w-full mb-7  px-4"></div>

                <div className="h-12 bg-gray-200 rounded-md animate-pulse mb-2 w-1/3"></div>
                <div className="h-20 bg-gray-200 rounded-md animate-pulse w-full  mb-7 px-4"></div>

                <div className="h-12 bg-gray-200 rounded-md animate-pulse mb-2 w-1/3"></div>
                <div className="h-20 bg-gray-200 rounded-md animate-pulse w-full mb-7  px-4"></div>

                <div className="h-12 bg-gray-200 rounded-md animate-pulse mb-2 w-1/3"></div>
                <div className="h-20 bg-gray-200 rounded-md animate-pulse w-full mb-7  px-4"></div>
              </div>
            ) : (
              activeQuestion && (
                <AnswerSection
                  answer={faqs.find((faq) => faq.question === activeQuestion)}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
