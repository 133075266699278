import React, { useState, useEffect } from 'react';
import Sidebar from './sidemenu';
import { Search, Copy, X, HelpCircle } from 'lucide-react';
import { getHowToUse } from '../lib/apiCalls';
import ReactMarkdown from 'react-markdown';

import { useLocation, useSearchParams } from 'react-router-dom';
import ShimmerLoader from './ShimmerLoader';


export default function HowToUse() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('user-info');
  const [activeTopic, setActiveTopic] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [howToUseData, setHowToUseData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const tabs = {
    'user-info': 'User Info',
    'concept_generation': 'Concept Generation',
    'post_generation': 'Post Drafting',
    // 'media_captioning': 'Media Captioning'
  };

  // Handle URL params and data fetching
  useEffect(() => { 
    const fetchHowToUseData = async () => {
      // setIsLoading(true);
      try {
        const response = await getHowToUse();
        if (response.success) {
          setHowToUseData(response.htu);
          
          // Get URL parameters
          const sectionFromURL = searchParams.get('section');
          const topicFromURL = searchParams.get('topic');
          
          // Set active tab from URL or default
          if (sectionFromURL && tabs[sectionFromURL]) {
            setActiveTab(sectionFromURL);
          }
          
          // Set active topic from URL or first topic in active section
          if (topicFromURL) {
            setActiveTopic(decodeURIComponent(topicFromURL));
          } else if (response.htu[activeTab]?.length > 0) {
            setActiveTopic(response.htu[activeTab][0].topic);
          }
        } else {
          setError('Failed to fetch how-to-use data.');
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchHowToUseData();
  }, [searchParams]); // Add searchParams as dependency

  // Update URL when tab changes
  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    setSearchParams(params => {
      params.set('section', newTab);
      return params;
    });
    
    // Set first topic of new tab if available
    if (howToUseData[newTab]?.length > 0) {
      const firstTopic = howToUseData[newTab][0].topic;
      setActiveTopic(firstTopic);
      setSearchParams(params => {
        params.set('topic', firstTopic);
        return params;
      });
    }
  };

  // Update URL when topic changes
  const handleTopicChange = (newTopic) => {
    setActiveTopic(newTopic);
    setSearchParams(params => {
      params.set('topic', newTopic);
      return params;
    });
  };

  const TabButton = ({ label, isActive, onClick }) => (
    <button
      className={`px-4 py-2 font-medium font-['Cormorant_Garamond',serif] ${
        isActive
          ? 'text-blue-600 border-b-2 border-blue-600'
          : 'text-gray-500 hover:text-gray-700'
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );

  const TopicItem = ({ topic, tooltip, isActive, onClick }) => (
    <div
      className={`p-4 cursor-pointer border font-['Cormorant_Garamond',serif] rounded-lg border-[#F7F7F7] ${
        isActive ? 'bg-gray-100' : 'hover:bg-gray-50'
      }`}
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <span>{topic}</span>
        <HelpCircle size={16} className="text-gray-400" title={tooltip} />
      </div>
    </div>
  );

  const filteredTopics = howToUseData[activeTab]
    ? howToUseData[activeTab].filter(item =>
        item.topic.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  const activeTopicData = activeTopic
    ? howToUseData[activeTab]?.find(item => item.topic === activeTopic)
    : null;

  // if (isLoading) {
  //   return (
  //     <div className="flex min-h-screen bg-[#FCFCFC]">
  //       <Sidebar />
  //       <div className=" ml-[380px]  min-w-[60vw] px-6">
  //        <ShimmerLoader />
  //       </div>
  //     </div>
  //   );
  // }

  if (error) {
    return (
      <div className="flex min-h-screen bg-[#FCFCFC]">
        <Sidebar />
        <div className="flex-1 p-8 ml-[380px] flex items-center justify-center">
          <p className="text-red-500">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen bg-[#FCFCFC]">
      <Sidebar />
      <div className="flex-1 p-8 ml-[380px]">
        <h1 className="text-3xl font-bold mb-8 font-['Cormorant_Garamond',serif]">How to use</h1>
        <div className="mb-6 flex justify-between items-center">
          <div className="space-x-4">
            {Object.entries(tabs).map(([key, label]) => (
              <TabButton
                key={key}
                label={label}
                isActive={activeTab === key}
                onClick={() => handleTabChange(key)}
              />
            ))}
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search topics..."
              className="pl-10 pr-4 py-2 border rounded-full w-64 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
        </div>
        <div className="flex gap-8">
          <div className="w-1/3">
            <h2 className="text-xl font-semibold mb-4 font-['Cormorant_Garamond',serif]">Topics</h2>
            <div className=" rounded-lg">
              { isLoading ? <ShimmerLoader />
                  : 
              (
              filteredTopics.map((item) => (
                <TopicItem
                  key={item.id}
                  topic={item.topic}
                  tooltip={item.tooltip}
                  isActive={activeTopic === item.topic}
                  onClick={() => handleTopicChange(item.topic)}
                />
              )))}
            </div>
          </div>
          <div className="w-2/3">
            { isLoading ? 
            <div className="flex-1 p-6 rounded-md animate-pulse w-full">
              <div className='flex  justify-between  mt-6'>
              <div className="h-8 bg-gray-200 rounded-md animate-pulse mb-4 w-1/3"></div>
              <div className='flex  justify-between gap-4'>
              <div className="h-8 bg-gray-200 rounded-md animate-pulse w-8"></div>
              <div className="h-8 bg-gray-200 rounded-md animate-pulse w-8"></div>
              </div>
              </div>
            <div className="h-24 bg-gray-200 rounded-md animate-pulse "></div>
          </div>  
                  : 
            (
            activeTopicData && (
              <div className="bg-white rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">{activeTopicData.topic}</h2>
                  <div className="flex space-x-2">
                    <button 
                      className="p-2 hover:bg-gray-100 rounded"
                      onClick={() => navigator.clipboard.writeText(activeTopicData['full-info'])}
                    >
                      <Copy size={20} className="text-gray-500" />
                    </button>
                    <button 
                      className="p-2 hover:bg-gray-100 rounded"
                      onClick={() => handleTopicChange(null)}
                    >
                      <X size={20} className="text-gray-500" />
                    </button>
                  </div>
                </div>
                <div className="prose text-[18px] max-w-none">
                  <ReactMarkdown>
                    {activeTopicData['full-info'] || activeTopicData.tooltip}
                  </ReactMarkdown>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}