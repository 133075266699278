import React, { createContext, useState, useContext, useEffect } from 'react';
import { getRecentGenerations, generatePost, deleteGenerations, deleteGeneration } from '../lib/apiCalls';

const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const [recentPosts, setRecentPosts] = useState([]);

  // useEffect(() => {
  //   fetchRecentPosts();
  // }, []);

  const fetchRecentPosts = async () => {
    try {
      const response = await getRecentGenerations('posts');
      
      if (response.success && response.data) {
        setRecentPosts(response.data);
      }
    } catch (error) {
      console.error('Error fetching recent posts:', error);
    }
  };

  // const addPost = async (style, mediaContext, topicDetails, targetAudience, files) => {
  //   try {
  //     const response = await generatePost(style, mediaContext, topicDetails, targetAudience, files);
  //     if (response.success) {
  //       console.log(response)
  //       setRecentPosts(prevPosts => [response.data[0], ...prevPosts].slice(0, 10));
  //       return response.data[0];
  //     }
  //   } catch (error) {
  //     console.error('Error adding post:', error);
  //     throw error;
  //   }
  // };


  const addPost = async (style, mediaContext, topicDetails, targetAudience, files) => {
    try {
      const response = await generatePost(style, mediaContext, topicDetails, targetAudience, files);
      if (response.success) {
        console.log('Generated posts:', response);
        
        setRecentPosts(prevPosts => {
          const updatedPosts = [...response.data, ...prevPosts];
          return updatedPosts.slice(0, 10);
        });
        
        return response.data;
      }
    } catch (error) {
      console.error('Error adding post:', error);
      throw error;
    }
  };

  const addPostFromConcept = async (data) => {
    try {
      
      if (data) {
        setRecentPosts(prevPosts => [data.data[0], ...prevPosts].slice(0, 10));
        return data.data[0];
      }
    } catch (error) {
      console.error('Error adding post:', error);
      throw error;
    }
  };

  const deleteAllPosts = async () => {
    try {
      await deleteGenerations('posts');
      setRecentPosts([]);
    } catch (error) {
      console.error('Error deleting all posts:', error);
      throw error;
    }
  };


  const deletePost = async (id) => {
    try {
      await deleteGeneration('posts', id);
      fetchRecentPosts()
    } catch (error) {
      console.error('Error deleting all posts:', error);
      throw error;
    }
  };



  return (
    <PostContext.Provider value={{ recentPosts, addPost, deleteAllPosts, fetchRecentPosts, addPostFromConcept, deletePost }}>
      {children}
    </PostContext.Provider>
  );
};

export const usePosts = () => useContext(PostContext);