import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './sidemenu';
import { Check } from 'lucide-react';
import { getBillingPackages, initiateRecharge, verifyRechargeSession } from '../lib/apiCalls';
import { Button } from './ui/button';

const PricingPlan = ({ name, price, features, description, priceId, onBuyCredits }) => (
  <div className="bg-white p-6 rounded-lg flex flex-col h-full max-w-sm">
    <h2 className="text-[24px] font-bold mb-4 font-['Cormorant_Garamond',serif] ">{name}</h2>
    <div className="flex items-center mb-4">
      <span className="text-[56px] font-bold font-['Cormorant_Garamond',serif]">${price}.00</span>
      <span className="ml-4 px-4 py-1 bg-gradient-to-r from-pink-400 to-purple-500 text-white rounded-full text-sm">
        One time
      </span>
    </div>
    <p className="text-gray-600 mb-6">{description}</p>
    <ul className="space-y-4 mb-8 flex-grow">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center">
          <div className="bg-purple-100 rounded-full p-1 mr-3">
            <Check className="text-purple-600" size={16} />
          </div>
          <span className="text-lg">{feature}</span>
        </li>
      ))}
    </ul>
    <Button 
      className="w-full bg-blue-600 text-white py-3 rounded-full text-[16px] font-semibold hover:bg-blue-700 transition duration-300"
      onClick={() => onBuyCredits(priceId)}
    >
      Buy Credits
    </Button>
  </div>
);

export default function PricingPage() {
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await getBillingPackages();
        if (response.success) {
          setPlans(response.products);
        } else {
          setError('Failed to fetch pricing plans');
        }
      } catch (err) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user');
          window.location.href = '/login';
        }
        setError('An error occurred while fetching pricing plans');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');
    if (sessionId) {
      verifyRechargeSession(sessionId)
        .then(response => {
          if (response.success) {
            console.log('Recharge successful:', response.message);
            navigate('/wallet', { replace: true });
          }
        })
        .catch(error => {
          if (error.response?.status === 401) {
            localStorage.removeItem('user');
            window.location.href = '/login';
          }
          console.error('Failed to verify recharge session:', error);
          setError('Failed to verify payment. Please contact support.');
        });
    }
  }, [location, navigate]);

  const handleBuyCredits = async (priceId) => {
    try {
      const response = await initiateRecharge(priceId);
      if (response.success && response.url) {
        window.location.href = response.url; // Redirect to Stripe checkout
      } else {
        setError('Failed to initiate payment process');
      }
    } catch (err) {
      if (error.response?.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
      setError('An error occurred while initiating payment');
    }
  };

  if (isLoading) {
    return (

      <div className="flex min-h-screen bg-[#F7F7F7]">
  <Sidebar />
  <div className="flex flex-col w-full justify-center items-center px-8 ml-[380px]">
    <h1 className="text-3xl font-bold mb-12 font-['Cormorant_Garamond',serif]">
      Choose Your Plan
    </h1>
    <div className="flex  w-full justify-center items-center gap-8 pl-[10%]">
      {Array.from({ length: 3 }).map((_, index) => (
        <div
          key={index}
          className="flex items-center justify-between bg-white rounded-md max-w-sm w-full border border-gray-100 p-7 "
        >
          <div className="flex flex-col w-full space-y-6 items-center justify-between flex-grow p-4">
            <div className="h-8 bg-gray-200 rounded-md animate-pulse w-full  "></div>
            <div className="h-8 bg-gray-200 rounded-md animate-pulse w-full "></div>
            <div className="h-8 bg-gray-200 rounded-md animate-pulse w-full "></div>
            <div className="h-8 bg-gray-200 rounded-md animate-pulse w-full "></div>
            <div className="h-8 bg-gray-200 rounded-md animate-pulse w-full "></div>
            <div className="h-8 bg-gray-200 rounded-md animate-pulse w-full "></div>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>

    );
  }

  if (error) {
    return (
      <div className="flex min-h-screen bg-[#F7F7F7]">
        <Sidebar />
        <div className="flex-1 flex justify-center items-center p-8 ml-[380px]">
          <p className="text-red-500">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen bg-[#F7F7F7]">
      <Sidebar />
      <div className="flex-1 flex flex-col justify-center items-center p-8 ml-[380px]">
        <h1 className="text-3xl font-bold mb-24 font-['Cormorant_Garamond',serif]">Choose Your Plan</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <PricingPlan
              key={index}
              name={plan.product_name}
              price={plan.unit_amount}
              features={plan.allowance}
              description={plan.product_description}
              priceId={plan.price_id}
              onBuyCredits={handleBuyCredits}
            />
          ))}
        </div>
      </div>
    </div>
  );
}