import * as React from "react"
import * as ProgressPrimitive from "@radix-ui/react-progress"

import { cn } from "../../lib/utils"

const Progress = React.forwardRef(({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn("relative h-4 w-full overflow-hidden rounded-full outline outline-1 outline-[#D0C2F1]", className)}
    {...props}>
    <ProgressPrimitive.Indicator
      className="h-full w-full flex-1  transition-all"
      style={{  background: "linear-gradient(90deg, #FFB2A1, #9747FF)",transform: `translateX(-${100 - (value || 0)}%)` }} />
  </ProgressPrimitive.Root>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
