import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBBnxqgnQ8VLqZ6edV-4k2f-Ar036DjF2o",
  authDomain: "fluffy-individuals.firebaseapp.com",
  projectId: "fluffy-individuals",
  storageBucket: "fluffy-individuals.firebasestorage.app",
  messagingSenderId: "563394110356",
  appId: "1:563394110356:web:b8d9f6acca65704a3de581",
  measurementId: "G-LJ3BEYS1F5"
};
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account"
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
