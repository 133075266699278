import React from "react";

const LoadingComponent = () => {
  return (
    <div className="fixed inset-0 bg-white backdrop-blur-lg flex items-center justify-center z-50">
      <div className="bg-white w-16 h-16 flex items-center justify-center rounded-md shadow-lg">
        <span className="text-transparent text-[75px] p-7 font-bold bg-clip-text animate-gradient-text">
          F
        </span>
      </div>

      {/* TailwindCSS Keyframes */}
      <style>
        {`
        @keyframes gradient-text {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        .animate-gradient-text {
          background-image: linear-gradient(
            to right,
            #af7df0,
            #6e21d1 25%,
            #9921d1 50%,
            #bd21d1 75%,
            #f505f5 100%
          );
          background-size: 200% 200%;
          animation: gradient-text 1s ease infinite;
        }
      `}
      </style>
    </div>
  );
};

export default LoadingComponent;
