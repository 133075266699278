import React, { useState, useRef, useEffect } from "react";
import {
  Copy,
  Download,
  HelpCircle,
  ImageIcon,
  Play,
  Trash2,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import Sidebar from "./sidemenu";
import Tooltip from "./tooltip";
import {
  deleteGeneration,
  deleteGenerations,
  generateMediaCaption,
  getRecentGenerations,
} from "../lib/apiCalls";
import { Button } from "./ui/button";
import { useUserInfo } from "../context/RefreshContext";
import ErrorPopup from "./ErrorPopup";
import ReactMarkdown from "react-markdown";

// Centralized error handling function
const handleError = (
  error,
  setError,
  setShowErrorPopup,
  setErrorPopupConfig
) => {
  if (error.response?.status === 401) {
    localStorage.removeItem("user");
    window.location.href = "/login";
    return;
  }

  let errorMessage = "An unexpected error occurred. Please try again.";
  let isPaymentRequired = false;

  if (error.response) {
    if (error.response.status === 402) {
      errorMessage = "Out of credits.";
      isPaymentRequired = true;
    } else if (error.response.data?.message) {
      errorMessage = error.response.data.message;
    }
  }

  setError(errorMessage);
  setShowErrorPopup(true);
  setErrorPopupConfig({
    message: errorMessage,
    link: isPaymentRequired ? "/recharge" : "",
  });

  setTimeout(() => setShowErrorPopup(false), 5000);
};

const isVideo = (src) => {
  const videoExtensions = [".mp4", ".webm", ".ogg"];
  return videoExtensions.some((ext) => src.toLowerCase().endsWith(ext));
};

// MarkdownComponents definition remains the same
const MarkdownComponents = {
  p: ({ children }) => (
    <p className="text-gray-700 text-[16px] mb-2">{children}</p>
  ),
  h1: ({ children }) => <h1 className="text-xl font-bold mb-2">{children}</h1>,
  h2: ({ children }) => <h2 className="text-lg font-bold mb-2">{children}</h2>,
  h3: ({ children }) => <h3 className="text-md font-bold mb-2">{children}</h3>,
  ul: ({ children }) => <ul className="list-disc ml-4 mb-2">{children}</ul>,
  ol: ({ children }) => <ol className="list-decimal ml-4 mb-2">{children}</ol>,
  li: ({ children }) => <li className="mb-1">{children}</li>,
  code: ({ children }) => (
    <code className="bg-gray-100 rounded px-1 py-0.5">{children}</code>
  ),
  pre: ({ children }) => (
    <pre className="bg-gray-100 rounded p-2 mb-2 overflow-x-auto">
      {children}
    </pre>
  ),
  blockquote: ({ children }) => (
    <blockquote className="border-l-4 border-gray-200 pl-4 italic my-2">
      {children}
    </blockquote>
  ),
  em: ({ children }) => <em className="italic">{children}</em>,
  strong: ({ children }) => <strong className="font-bold">{children}</strong>,
  a: ({ href, children }) => (
    <a href={href} className="text-purple-600 hover:text-purple-700 underline">
      {children}
    </a>
  ),
};

const MediaItem = ({ txt, src, onDelete, isNew }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isVideoFile = isVideo(src);
  const shouldShowExpand = txt.length > 150;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="border border-[#F5F5F5] rounded-md overflow-hidden mb-4">
      <div className="p-4 flex justify-between items-center">
        <div className="flex justify-start mt-2 space-x-2">
          <button
            className="p-1 border border-[#F5F5F5] rounded-lg hover:bg-gray-50"
            onClick={() => window.open(src, "_blank")}
          >
            <Download size={20} className="text-gray-400 hover:text-gray-600" />
          </button>
          <button
            className="p-1 border border-[#F5F5F5] rounded-lg hover:bg-gray-50"
            onClick={() => navigator.clipboard.writeText(src)}
          >
            <Copy size={20} className="text-gray-400 hover:text-gray-600" />
          </button>
          <button
            className="p-1 border border-[#F5F5F5] rounded-lg hover:bg-gray-50"
            onClick={onDelete}
          >
            <Trash2 size={20} className="text-gray-400 hover:text-gray-600" />
          </button>
        </div>

        {isNew && (
          <span className="bg-white border rounded-full px-6 py-3 mr-5 text-purple-600 border-purple-600 text-xs font-bold">
            NEW
          </span>
        )}
      </div>
      <div className="relative">
        <div className={`pt-4 pr-4 pl-4 ${!isExpanded ? "line-clamp-3" : ""}`}>
          <div className="prose prose-sm max-w-none">
            <ReactMarkdown components={MarkdownComponents}>
              {String(txt)}
            </ReactMarkdown>
          </div>
        </div>
        {shouldShowExpand && (
          <button
            onClick={toggleExpand}
            className="text-purple-600 text-xs font-semibold px-3 py-2 flex items-center hover:text-purple-700"
          >
            {isExpanded ? (
              <>
                Show less <ChevronUp className="ml-1 w-4 h-4" />
              </>
            ) : (
              <>
                See more <ChevronDown className="ml-1 w-4 h-4" />
              </>
            )}
          </button>
        )}
      </div>

      <div className="relative rounded-lg">
        {isVideoFile ? (
          <video
            src={src}
            className="w-full rounded-lg h-48 object-cover"
            controls
          />
        ) : (
          <img
            src={src}
            alt="Generated caption"
            className="w-full rounded-lg h-48 object-cover"
          />
        )}
        {isVideoFile && (
          <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-2">
            <Play size={24} className="text-purple-600" />
          </button>
        )}
      </div>
    </div>
  );
};

export default function MediaCaptioning() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0);
  const [mediaContext, setMediaContext] = useState("");
  const [topicDetails, setTopicDetails] = useState("");
  const [additionalInstructions, setadditionalInstructions] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [recentCaptions, setRecentCaptions] = useState([]);
  const [newCaptionIds, setNewCaptionIds] = useState(new Set());
  const [error, setError] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorPopupConfig, setErrorPopupConfig] = useState({
    message: "",
    link: "",
  });
  const fileInputRef = useRef(null);

  const { fetchUserInfo } = useUserInfo();

  const fetchRecentCaptions = async () => {
    try {
      const response = await getRecentGenerations("media-captions");
      if (response.success && response.data) {
        const recentGeneratedCaptions = response.data.map((caption) => ({
          id: caption.id,
          content_url: caption.content_url,
          content_text: caption.content_text || "undefined",
        }));
        setRecentCaptions(recentGeneratedCaptions);
        localStorage.setItem(
          "recentCaptions",
          JSON.stringify(recentGeneratedCaptions)
        );
      }
    } catch (error) {
      handleError(error, setError, setShowErrorPopup, setErrorPopupConfig);
    }
  };

  useEffect(() => {
    fetchRecentCaptions();
  }, []);

  const handleFiles = (files) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrls((prevUrls) => [...prevUrls, reader.result]);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    handleFiles(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleFiles(files);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviewUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
    if (currentPreviewIndex >= index && currentPreviewIndex > 0) {
      setCurrentPreviewIndex((prev) => prev - 1);
    }
  };

  const handlePreviousPreview = () => {
    setCurrentPreviewIndex((prev) =>
      prev > 0 ? prev - 1 : previewUrls.length - 1
    );
  };

  const handleNextPreview = () => {
    setCurrentPreviewIndex((prev) =>
      prev < previewUrls.length - 1 ? prev + 1 : 0
    );
  };

  const handleGenerateCaption = async () => {
    if (selectedFiles.length === 0) {
      setError("Please select at least one file.");
      return;
    }

    setIsGenerating(true);
    setError(null);

    try {
      const response = await generateMediaCaption(
        selectedFiles,
        mediaContext,
        topicDetails,
        additionalInstructions
      );
      if (response.success) {
        const newCaptions = response.data.map((item) => ({
          id: item.id,
          content_url: item.content_url,
          content_text: item.content_text,
        }));
        setRecentCaptions((prevCaptions) => {
          const updatedCaptions = [...newCaptions, ...prevCaptions];
          localStorage.setItem(
            "recentCaptions",
            JSON.stringify(updatedCaptions)
          );
          return updatedCaptions;
        });
        setNewCaptionIds(new Set(newCaptions.map((caption) => caption.id)));
        await fetchUserInfo();
      } else {
        throw new Error("Failed to generate caption");
      }
    } catch (error) {
      handleError(error, setError, setShowErrorPopup, setErrorPopupConfig);
    } finally {
      setIsGenerating(false);
    }
  };

  const clearAll = async () => {
    try {
      await deleteGenerations("media-captions");
      setRecentCaptions([]);
      setNewCaptionIds(new Set());
      localStorage.removeItem("recentCaptions");
    } catch (error) {
      handleError(error, setError, setShowErrorPopup, setErrorPopupConfig);
    }
  };

  const deleteCaption = async (id) => {
    try {
      await deleteGeneration("media-captions", id);
      await fetchRecentCaptions();
    } catch (error) {
      handleError(error, setError, setShowErrorPopup, setErrorPopupConfig);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-50 font-sans">
      <Sidebar />
      <div className="flex-1 p-8 ml-[380px] h-screen overflow-y-scroll scrollbar-hidden">
        {showErrorPopup && (
          <ErrorPopup
            message={errorPopupConfig.message}
            link={errorPopupConfig.link}
          />
        )}
        <div className="max-w-3xl mx-auto">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold mr-2 font-['Cormorant_Garamond',serif]">
              Video Captioning
            </h1>
            <Tooltip
              content="Generate captions for your media"
              userGuidePath="www.example.com/user-guide"
            />
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <label className="text-sm flex gap-2 items-center font-medium text-gray-700">
                  Media
                  <Tooltip
                    content="Upload your media files here"
                    userGuidePath="www.example.com/user-guide"
                  />
                </label>
              </div>
              {/* <div 
                className="border-2 border-dashed border-gray-300 rounded-lg p-12 text-center cursor-pointer hover:border-gray-400 relative"
                onClick={() => fileInputRef.current.click()}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <input 
                  type="file" 
                  ref={fileInputRef} 
                  onChange={handleFileSelect} 
                  className="hidden" 
                  accept="image/*,video/*"
                  multiple
                />
                {previewUrls.length > 0 ? (
                  <div className="relative">
                    <img src={previewUrls[currentPreviewIndex]} alt="Preview" className="w-full h-48 object-cover rounded-lg" />
                    <button 
                      className="absolute top-2 right-2 bg-white rounded-full p-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveFile(currentPreviewIndex);
                      }}
                    >
                      <Trash2 size={20} className="text-red-500" />
                    </button>
                    {previewUrls.length > 1 && (
                      <>
                        <button 
                          className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white rounded-full p-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreviousPreview();
                          }}
                        >
                          <ChevronLeft size={20} className="text-gray-600" />
                        </button>
                        <button 
                          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white rounded-full p-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNextPreview();
                          }}
                        >
                          <ChevronRight size={20} className="text-gray-600" />
                        </button>
                      </>
                    )}
                    <p className="mt-2 text-sm text-gray-500">{currentPreviewIndex + 1} / {previewUrls.length}</p>
                  </div>
                ) : (
                  <>
                    <ImageIcon className="mx-auto text-gray-400 mb-4" size={48} />
                    <p><span className="text-blue-600 underline">Click</span> to upload or Drag and Drop files here</p>
                  </>
                )}
              </div> */}
            </div>

            {/* <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <label className="text-sm flex gap-2 font-medium text-gray-700">
                  Provide media context <span className="text-purple-400">(optional)</span>
                  <Tooltip 
                    content="Describe the context of your media" 
                    userGuidePath="www.example.com/user-guide"
                  />
                </label>
                <span className="text-sm text-gray-400">{mediaContext.split(' ').length}/250 words</span>
              </div>
              <textarea
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                rows="4"
                placeholder="Like where the photo was taken, who are the participants, etc"
                value={mediaContext}
                onChange={(e) => setMediaContext(e.target.value)}
              ></textarea>
            </div>
            
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <label className="text-sm flex gap-2  font-medium text-gray-700">
                  Post Topic/Details <span className="text-purple-400">(optional)</span>
                  <Tooltip 
                    content="Provide details about your post topic" 
                    userGuidePath="www.example.com/user-guide"
                  />
                </label>
                <span className="text-sm text-gray-400">{topicDetails.split(' ').length}/250 words</span>
              </div>
              <textarea
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                rows="4"
                placeholder="e.g I want a post about my childhood story..."
                value={topicDetails}
                onChange={(e) => setTopicDetails(e.target.value)}
              ></textarea>
            </div>
            
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <label className="text-sm flex gap-2  font-medium text-gray-700">
                  Additional Instructions <span className="text-purple-400">(optional)</span>
                  <Tooltip 
                    content="Describe your target audience" 
                    userGuidePath="www.example.com/user-guide"
                  />
                </label>
                <span className="text-sm text-gray-400">{additionalInstructions.split(' ').length}/250 words</span>
              </div>
              <textarea
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                rows="4"
                placeholder="e.g Adults age 30-35..."
                value={additionalInstructions}
                onChange={(e) => setadditionalInstructions(e.target.value)}
              ></textarea>
            </div> */}

            <Button
              className="w-full bg-purple-600 text-white py-3 rounded-full hover:bg-purple-700 transition duration-300"
              // onClick={handleGenerateCaption}
              // disabled={isGenerating}
              disabled={true}
            >
              {/* {isGenerating ? 'Generating...' : 'Generate $0.10'} */}
              Coming Soon
            </Button>

            {/* {error && <p className="text-red-500 mt-2">{error}</p>} */}
          </div>
        </div>
      </div>

      <div className="flex-1 bg-white p-6 mr-10 h-screen overflow-y-scroll scrollbar-hidden">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold font-['Cormorant_Garamond',serif]">
            Recent
          </h2>
          {recentCaptions.length > 0 && (
            <button
              className="text-red-500 hover:text-red-700"
              onClick={clearAll}
            >
              Clear all
            </button>
          )}
        </div>

        {recentCaptions.length > 0 ? (
          recentCaptions.map((caption) => (
            <MediaItem
              key={caption.id}
              src={caption.content_url}
              txt={caption.content_text}
              onDelete={() => deleteCaption(caption.id)}
              isNew={newCaptionIds.has(caption.id)}
            />
          ))
        ) : (
          <div className="flex flex-col items-center justify-center h-full text-gray-400">
            <img
              src="/No-Results.png"
              alt="Concept"
              className="mb-4 w-[140px] h-[119px]"
            />
            <p>Nothing here yet!</p>
          </div>
        )}
      </div>
    </div>
  );
}
