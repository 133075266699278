import React, { useEffect ,useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { HandleProvider } from './context/HandleContext';
import { PostProvider } from './context/PostContext';
import UserInfo from './pages/user-info/user-info';
import Login from './pages/auth/login';
import Signup from './pages/auth/signup';
import Dashboard from './pages/dashboard/dashboard';
import Generate from './pages/content/generate';
import PostGeneration from './pages/post/postGeneration';
import Captioning from './pages/media/captioning';
import HelpCenter from './pages/help/help-center';
import HowToUse from './pages/help/HowToUse';
import Wallet from './pages/wallet/wallet';
import { UserProvider } from './context/RefreshContext';
import RechargeSuccess from './pages/wallet/success';
import {
  getUserInfo,
} from "./lib/apiCalls";
import LoadingComponent from './PageComponents/LoadingComponent';
function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchUserInfo();
  }, []);

   const fetchUserInfo = async () => {
      if (sessionStorage.getItem("visited")) {
        setLoading(false);
        return;
      }

      // if (sessionStorage.getItem("userData")) {
      //   setLoading(false);
      //   return;
      // }
      try {
        const response = await getUserInfo();
        if (response.success) {
          const { data } = response;
          sessionStorage.setItem("userData", JSON.stringify(data));
          setLoading(false);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          sessionStorage.setItem("visited", true);
         window.location.href = "/login";
        }
        console.error("Failed to fetch user info:", error);
      } 
    };

    if (loading) {
      return <LoadingComponent />;
    }
  return (
    <Router>
      <UserProvider>
      <AuthProvider>
        <HandleProvider>
          <PostProvider>
          {/* {loading ? (
                <LoadingComponent />
              ) : ( */}
                <Routes>
             
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/login" element={sessionStorage.getItem("userData") ?  <Navigate to="/dashboard" replace /> : <Login /> } />
              <Route path="/signup" element={sessionStorage.getItem("userData") ?  <Navigate to="/dashboard" replace /> : <Signup />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user-info" element={<UserInfo />} />
              <Route path="/user-info/:check" element={<UserInfo />} />
              <Route path="/concept-generation" element={<Generate to="/" />} />
              <Route path="/post-generation" element={<PostGeneration to="/" />} />
              <Route path="/media-captioning" element={<Captioning to="/" />} />
              <Route path="/help-and-support" element={<HelpCenter to="/" />} />
              <Route path="/how-to-use" element={<HowToUse to="/" />} />
              <Route path="/wallet-recharge" element={<Wallet to="/" />} />
              <Route path="/wallet-recharge/success" element={<RechargeSuccess />} />
              <Route path="*" element={<Navigate to="/how-to-use" replace />} />
            </Routes>
             {/* )} */}
          </PostProvider>
        </HandleProvider>
      </AuthProvider>
      </UserProvider>
    </Router>
  );
}

export default App;