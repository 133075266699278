import axios from 'axios';

const API_BASE_URL = 'https://backend-individuals.fluffy-ai.com/api';

export const getUserInfo = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/info`,{
      withCredentials: true
    });
    return response.data;
  } catch (error) {

    if (error.response?.status === 401) {
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    
    console.error('Error fetching user info:', error);
    throw error;
  }
};

export const updateUserInfo = async (userData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/user/info`, userData,{
      withCredentials: true
    });
    return response.data;
  } catch (error) {

    if (error.response?.status === 401) {
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    console.error('Error updating user info:', error);
    throw error;
  }
};

export const logout = async () => { 
  try {
    const response = await axios.post(`${API_BASE_URL}/user/logout`,{},{
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
}

export const sendSupportMessage = async (message) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/support/send-message`, { text: message },{
      withCredentials: true
    });
    return response.data;
  } catch (error) {

    if (error.response?.status === 401) {
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
    console.error('Error sending support message:', error);
    throw error;
  }
};


export const getBillingPackages = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/billing/packages`,{
        withCredentials: true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error fetching billing packages:', error);
      throw error;
    }
  };
  
  export const initiateRecharge = async (priceId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/user/wallet/recharge/${priceId}`, {},{
        withCredentials: true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error initiating recharge:', error);
      throw error;
    }
  };

  export const verifyRechargeSession = async (sessionId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/user/wallet-recharge/session/${sessionId}`, {},{
        withCredentials: true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error verifying recharge session:', error);
      throw error;
    }
  };


  export const generateConcept = async (handles, additionalInstructions) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/user/generate/concept`, {
        handles: handles.map(h => ({ type: h.platform.toLowerCase(), reference: h.handle })),
        additional_instructions: additionalInstructions
      },{
        withCredentials: true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error generating concept:', error);
      throw error;
    }
  };



  export const generatePost = async (style, mediaContext, topicDetails, additionalInstructions, files) => {
    try {
      const formData = new FormData();
      files?.forEach((file, index) => {
        formData.append(`files`, file);
      });

      // console.log(files)

      if(files.length>0){
        const response = await axios.post(
          `${API_BASE_URL}/user/generate/post?style=${encodeURIComponent(style)}&media_context=${encodeURIComponent(mediaContext)}&topic_details=${encodeURIComponent(topicDetails)}&additional_instructions=${encodeURIComponent(additionalInstructions)}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
          }
        );
        console.log(response.data)
        return response.data;
      }else{

        const formData = new FormData();
        formData.append('style', style);
        formData.append('media_context', mediaContext);
        formData.append('topic_details', topicDetails);
        formData.append('additional_instructions', additionalInstructions);

        const response = await axios.post(
          `${API_BASE_URL}/user/generate/post`,
          formData,{
            withCredentials: true
          });
        return response.data;

      }
  
      
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error generating post:', error);
      throw error;
    }
  };

  export const generateMediaCaption = async (files, mediaContext, topicDetails, additionalInstructions) => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append('files', file);
    });
  
    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/generate/media-captions?media_context=${encodeURIComponent(mediaContext)}&topic_details=${encodeURIComponent(topicDetails)}&additional_instructions=${encodeURIComponent(additionalInstructions)}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true
        }
      );
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error generating media caption:', error);
      throw error;
    }
  };


  export const getFAQs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/faqs`,{
        withCredentials: true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error fetching FAQs:', error);
      throw error;
    }
  };


  export const getHowToUse = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/how-to-use`,{
        withCredentials: true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error fetching how-to-use data:', error);
      throw error;
    }
  };


  export const getRecentActivities = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user/recent-activities`,{
        withCredentials:true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error fetching recent activities:', error);
      throw error;
    }
  };

  export const getRecentGenerations = async (type = 'all') => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user/recent-generations/${type}`,{
        withCredentials:true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error(`Error fetching recent ${type} generations:`, error);
      throw error;
    }
  };



  export const getUsageStats = async (month) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user/dashboard/usage/${month}/stats`,{
        withCredentials: true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error fetching usage stats:', error);
      throw error;
    }
  };





  export const getCosts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/costs`,{
        withCredentials: true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error fetching costs:', error);
      throw error;
    }
  };




  export const clearRecentActivities = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/user/recent-activities`,{
        withCredentials: true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error('Error clearing recent activities:', error);
      throw error;
    }
  };
  

  

  export const deleteGenerations = async (type) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/user/generation/${type}`,{
        withCredentials: true
      });
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      console.error(`Error deleting ${type} generations:`, error);
      throw error;
    }
  };





  export const deleteGeneration = async (type, id) => {
    try {
      // Construct the URL based on the type and id
      const url = `${API_BASE_URL}/user/generation/${type}/${id}`;
  
      // Make the DELETE request
      const response = await axios.delete(url, {
        headers: {
          'accept': 'application/json'
        }
      },{
        withCredentials: true
      });
  
      // Handle successful response
      console.log('Resource deleted successfully:', response.data);
      return response.data;
    } catch (error) {

      if (error.response?.status === 401) {
        localStorage.removeItem('user');
      window.location.href = '/login';
    }
      // Handle any errors
      console.error('Error deleting resource:', error);
      throw error;
    }
  };