import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import {
  LogOut,
  LayoutDashboard,
  User,
  Lightbulb,
  FileText,
  Image,
  HelpCircle,
  HeadphonesIcon,
  // ChevronDown,
} from "lucide-react";
// import { useHandles } from "../context/HandleContext";
// import { useUserInfo } from "../context/RefreshContext";
import { Link } from "react-router-dom";
import { logout } from "../lib/apiCalls";

export default function Sidebar({ balance }) {
  const location = useLocation();
  const navigate = useNavigate();
  // const { setHandles, setRelatedHandles } = useHandles();
  // const { fetchUserInfo } = useUserInfo();
  const [userInfo, setUserInfo] = useState(
    sessionStorage.getItem("userData")
      ? JSON.parse(sessionStorage.getItem("userData"))
      : null
  );
  const [isLoading, setIsLoading] = useState(false);

  // const updateHandles = useCallback(
  //   (data) => {
  //     if (data.your_handles) {
  //       setHandles(
  //         data.your_handles.map((handle) => ({
  //           handle: handle.reference,
  //           platform: handle.type,
  //         }))
  //       );
  //     }

  //     if (data.related_handles) {
  //       setRelatedHandles(
  //         data.related_handles.map((handle) => ({
  //           handle: handle.reference,
  //           platform: handle.type,
  //         }))
  //       );
  //     }
  //   },
  //   [setHandles, setRelatedHandles]
  // );

  // useEffect(() => {

  // if (sessionStorage.getItem("userData")) {
  //   const data = JSON.parse(sessionStorage.getItem("userData"));
  //   // updateHandles(data);

  //   setUserInfo(data);
  //   console.log(data);

  //   return;
  // }
  // const initUserInfo = async () => {
  //   setIsLoading(true);
  //   const data = await fetchUserInfo();
  //   console.log(data);
  //   if (data) {
  //     setUserInfo(data);
  //     // updateHandles(data);
  //   }
  //   setIsLoading(false);
  // };

  // initUserInfo();

  //   setUserInfo(userData);
  //   console.log(userData);
  // }, []);

  const goToWallet = () => {
    navigate("/wallet-recharge");
  };

  const navItems = [
    {
      icon: <LayoutDashboard size={20} />,
      text: "Dashboard",
      path: "/dashboard",
    },
    { icon: <User size={20} />, text: "User Info", path: "/user-info" },
    {
      icon: <Lightbulb size={20} />,
      text: "Concept Generation",
      path: "/concept-generation",
    },
    {
      icon: <FileText size={20} />,
      text: "Post Drafting",
      path: "/post-generation",
    },
    {
      icon: <Image size={20} />,
      text: "Video Captioning",
      path: "/media-captioning",
    },
  ];

  const helpItems = [
    { icon: <HelpCircle size={20} />, text: "How to use", path: "/how-to-use" },
    {
      icon: <HeadphonesIcon size={20} />,
      text: "Help and support",
      path: "/help-and-support",
    },
  ];

  const handleLogout = async () => {
    // document.cookie = "session=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    try {
        const response = await logout(); // Ensure 'logout' is an async function that returns a Promise
        console.log(response);
        
        localStorage.removeItem("user"); // No need for await
        sessionStorage.clear(); // No need for await

        navigate("/login");
    } catch (error) {
        console.error("Error during logout:", error);
        // Handle the error appropriately here
    }
};

  return (
    <aside className="w-64 min-w-[360px] h-[97vh] fixed pl-[40px] pr-[20px] box-border border border-[#F7F7F7] bg-white text-black p-4 m-4 rounded-3xl flex flex-col">
      <div className="mb-8 flex items-center space-x-2">
        <img
          src="/favicon.png"
          className="w-[30px] h-[30px]"
          alt="Fluffy logo"
        />
        <span className="text-[36px] font-[700] font-['Cormorant_Garamond',serif] ">
          Fluffy
        </span>
      </div>
      <nav className="flex-grow space-y-1">
        {navItems.map((item, index) => (
          <NavItem
            key={index}
            icon={item.icon}
            text={item.text}
            path={item.path}
            active={location.pathname === item.path}
          />
        ))}
      </nav>

      <nav className="flex-grow mt-10">
        {helpItems.map((item, index) => (
          <NavItem
            key={index}
            icon={item.icon}
            text={item.text}
            path={item.path}
            active={location.pathname === item.path}
          />
        ))}
      </nav>
      <div className="mt-10 relative">
        <img
          src="/wallet.png"
          alt="Wallet"
          className="w-[118px] h-[118px] absolute right-1 -top-2"
        />
        {isLoading ? (
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-500 mx-auto"></div>
        ) : (
          <>
            {userInfo?.current_balance > 0 && (
              <p className="text-[16px] text-[#676767] mb-2">Wallet balance</p>
            )}
            <h2 className="text-[40px] font-bold mb-3">
              $
              {userInfo
                ? balance
                  ? `${balance?.toFixed(2)}`
                  : userInfo.current_balance?.toFixed(2)
                : "0.00"}
            </h2>
            <div className="text-[16px] text-[#676767] mb-2">
              {(userInfo?.current_balance === 0 ||
                !userInfo?.current_balance) &&
                "Recharge your wallet to start generating"}
            </div>
          </>
        )}
        <Button
          className="w-full h-[50px] bg-blue-600 hover:bg-blue-700 text-white rounded-full"
          onClick={goToWallet}
        >
          Recharge
        </Button>
      </div>
      <div className="mt-8 relative">
        <Input
          value={userInfo ? userInfo.email : ""}
          className="text-[#1E293B] bg-none border rounded-lg pr-8"
          readOnly
        />
        <LogOut
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 cursor-pointer hover:scale-110 transition duration-300"
          size={16}
          onClick={() => {
            handleLogout();
          }}
        />
      </div>
    </aside>
  );
}

// function NavItem({ icon, text, path, active }) {
//   return (
//     <Link
//       to={path}
//       className={`flex items-center space-x-2 px-3 py-2 rounded-lg text-sm font-['Cormorant_Garamond',serif] ${
//         active
//           ? "bg-[#112863] text-white py-2"
//           : "text-gray-400 hover:bg-gray-100 hover:text-gray-700"
//       }`}
//     >
//       {icon}
//       <span>{text}</span>
//     </Link>
//   );
// }

const NavItem = React.memo(({ icon, text, path, active }) => (
  <Link
    to={path}
    className={`flex items-center space-x-2 px-3 py-2 rounded-lg text-sm font-['Cormorant_Garamond',serif] ${
      active
        ? "bg-[#112863] text-white py-2"
        : "text-black hover:bg-gray-100 hover:text-gray-700"
    }`}
  >
    {icon}
    <span>{text}</span>
  </Link>
));
