import React from "react";

const ShimmerLoader = () => {
  return (
    <div className="space-y-6 w-full flex flex-col  justify-between mt-2 ">
    
      {/* <h1 className="text-3xl font-bold font-['Cormorant_Garamond',serif]">How to use</h1>
      <div className="h-14 mb-14 bg-gray-300 rounded-md animate-pulse w-1/4"></div>

      
      <div className="flex space-x-4 ">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="h-12 w-24 bg-gray-300 rounded-md animate-pulse"></div>
        ))}
      </div> */}

      {/* Content Loader */}
      <div className="flex mx-auto w-full ">
        {/* Left Sidebar */}
      
        <div className="w-full space-y-4 pr-6 ">
          {[...Array(7)].map((_, i) => (
            <div
              key={i}
              className="h-10 bg-gray-200 rounded-md animate-pulse"
            ></div>
          ))}
        </div>

        {/* Right Panel */}
         {/* <div className="flex-1 p-6 bg-gray-200 rounded-md animate-pulse w-full">
          <div className="h-8 bg-gray-300 rounded-md animate-pulse mb-4"></div>
          <div className="h-6 bg-gray-300 rounded-md animate-pulse w-3/5"></div>
        </div>   */}
      </div>
    </div>
  );
};

export default ShimmerLoader;
