import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  HelpCircle,
  X,
  Download,
  Copy,
  ChevronDown,
  ChevronUp,
  Save,
  Trash2,
} from "lucide-react";
import Sidebar from "./sidemenu";
import { Button } from "./ui/button";
import Tooltip from "./tooltip";
import { useHandles } from "../context/HandleContext";
import { usePosts } from "../context/PostContext";
import { useNavigate } from "react-router-dom";
import HandleInputs from "./HandleInputs";
import axios from "axios";
import { useUserInfo } from "../context/RefreshContext";
import ErrorPopup from "./ErrorPopup";

const API_BASE_URL = "https://backend-individuals.fluffy-ai.com/api";

// Centralized error handling function
const handleError = (error, setError, setShowErrorPopup) => {
  if (error.response?.status === 401) {
    localStorage.removeItem("user");
    window.location.href = "/login";
    return;
  }

  let errorMessage = "An unexpected error occurred. Please try again.";
  let isPaymentRequired = false;

  if (error.response) {
    if (error.response.status === 402) {
      errorMessage = "Out of credits.";
      isPaymentRequired = true;
    } else if (error.response.data?.message) {
      errorMessage = error.response.data.message;
    }
  }

  setError(errorMessage);
  setShowErrorPopup(true);

  // Hide error popup after 5 seconds
  setTimeout(() => setShowErrorPopup(false), 5000);

  return { errorMessage, isPaymentRequired };
};

const generateConcept = async (
  handles,
  relatedHandles,
  additionalInstructions
) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/user/generate/concept`,
      {
        handles: handles.map((h) => ({
          type: h.platform.toLowerCase(),
          reference: h.handle,
        })),
        related_handles: relatedHandles.map((h) => ({
          type: h.platform.toLowerCase(),
          reference: h.handle,
        })),
        additional_instructions: additionalInstructions,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCosts = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/costs`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getRecentGenerations = async (type = "all") => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/user/recent-generations/${type}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const generatePost = async (concept) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/user/generate/concept/post`,
      {
        concept_type: "mainstream/offstream",
        name: concept.name,
        concept: {
          id: concept.concept.id,
          created_at: concept.concept.created_at,
          description: concept.concept.description,
          details: concept.concept.details,
          practial_tips: concept.concept.practical_tips,
          inspiration: concept.concept.inspiration,
        },
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const savePost = async (title, content) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/user/generations/post/save`,
      {
        title,
        content,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function ConceptGeneration() {
  const { handles: initialHandles, relatedHandles: initialRelatedHandles } =
    useHandles();
  const [handles, setHandles] = useState(initialHandles);
  const [relatedHandles, setRelatedHandles] = useState(initialRelatedHandles);
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [concepts, setConcepts] = useState({ mainstream: [], offstream: [] });
  const [activeTab, setActiveTab] = useState("mainstream");
  const [error, setError] = useState(null);
  const [costs, setCosts] = useState(JSON.parse(sessionStorage.getItem("costs")) || {});
  const [generatedPosts, setGeneratedPosts] = useState([]);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [additionalInstructions, setadditionalInstructions] = useState("");
  const [errorPopupConfig, setErrorPopupConfig] = useState({
    message: "",
    link: "",
  });
  // const [cost, setCost] = useState(0.10);
  // const { fetchUserInfo } = useUserInfo();
  // const navigate = useNavigate();
  // const { addPostFromConcept } = usePosts();
  // const modalRef = useRef(null);

  useEffect(() => {
    fetchCosts();
    fetchRecentConcepts();
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    if (userData?.current_balance) {
      setCurrentBalance(userData.current_balance);
    }
  }, []);

  const fetchCosts = async () => {
    if (sessionStorage.getItem("costs")) {
      setCosts(JSON.parse(sessionStorage.getItem("costs")));
      return;
    }
    try {
      const response = await getCosts();
      if (response.success) {
        console.log(response);
        setCosts(response.costs);
      }
    } catch (error) {
      const { isPaymentRequired } = handleError(
        error,
        setError,
        setShowErrorPopup
      );
      setErrorPopupConfig({
        message: error.response?.data?.message || "Error fetching costs",
        link: isPaymentRequired ? "/wallet-recharge" : "",
      });
    }
  };


  const fetchRecentConcepts = async () => {
    setLoading(true);
    try {
      const response = await getRecentGenerations("all");
      if (response.success && response.data.concepts_generated) {
        setConcepts({
          mainstream: response.data.concepts_generated.mainstream || [],
          offstream: response.data.concepts_generated.offstream || [],
        });
      }
    } catch (error) {
      const { isPaymentRequired } = handleError(
        error,
        setError,
        setShowErrorPopup
      );
      setErrorPopupConfig({
        message:
          error.response?.data?.message || "Error fetching recent concepts",
        link: isPaymentRequired ? "/wallet-recharge" : "",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateConcept = async () => {
    setIsGenerating(true);
    setError(null);
    try {
      const response = await generateConcept(
        handles,
        relatedHandles,
        additionalInstructions
      );
      if (response.success) {
        setConcepts((prevConcepts) => ({
          mainstream: [...response.data.mainstream, ...prevConcepts.mainstream],
          offstream: [...response.data.offstream, ...prevConcepts.offstream],
        }));
        // fetchUserInfo();
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        const updatedBalance = (userData.current_balance - costs["concepts-generation"]).toFixed(2);
        userData.current_balance = parseFloat(updatedBalance);
        sessionStorage.setItem("userData", JSON.stringify(userData));

        // Update state to trigger re-render
        setCurrentBalance(userData.current_balance);
      } else {
        throw new Error("Failed to generate concepts");
      }
    } catch (error) {
      const { isPaymentRequired } = handleError(
        error,
        setError,
        setShowErrorPopup
      );
      setErrorPopupConfig({
        message: error.response?.data?.message || "Error generating concepts",
        link: isPaymentRequired ? "/wallet-recharge" : "",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  // const handleGeneratePost = async () => {
  //   if (selectedConcept) {
  //     setIsGeneratingPost(true);
  //     try {
  //       const response = await generatePost(selectedConcept);
  //       if (response.success) {
  //         setGeneratedPosts(response.data);
  //         setIsPostModalOpen(true);
  //         fetchUserInfo();
  //       } else {
  //         throw new Error("Failed to Draft Post");
  //       }
  //     } catch (error) {
  //       const { isPaymentRequired } = handleError(
  //         error,
  //         setError,
  //         setShowErrorPopup
  //       );
  //       setErrorPopupConfig({
  //         message: error.response?.data?.message || "Error generating post",
  //         link: isPaymentRequired ? "/wallet-recharge" : "",
  //       });
  //     } finally {
  //       setIsGeneratingPost(false);
  //     }
  //   }
  // };

  const handleSavePost = async (title, content) => {
    try {
      const response = await savePost(title, content);
      if (response.success) {
        console.log("Post saved successfully");
      } else {
        throw new Error("Failed to save post");
      }
    } catch (error) {
      const { isPaymentRequired } = handleError(
        error,
        setError,
        setShowErrorPopup
      );
      setErrorPopupConfig({
        message: error.response?.data?.message || "Error saving post",
        link: isPaymentRequired ? "/wallet-recharge" : "",
      });
    }
  };

  const handleAddHandle = (type, handle, platform) => {
    if (type === "handles") {
      setHandles((prevHandles) => [...prevHandles, { handle, platform }]);
    } else if (type === "relatedHandles") {
      setRelatedHandles((prevHandles) => [
        ...prevHandles,
        { handle, platform },
      ]);
    }
  };

  const handleRemoveHandle = (type, index) => {
    if (type === "handles") {
      setHandles((prevHandles) => prevHandles.filter((_, i) => i !== index));
    } else if (type === "relatedHandles") {
      setRelatedHandles((prevHandles) =>
        prevHandles.filter((_, i) => i !== index)
      );
    }
  };

  const handleUpdateHandle = (type, index, handle, platform) => {
    if (type === "handles") {
      setHandles((prevHandles) =>
        prevHandles.map((h, i) => (i === index ? { handle, platform } : h))
      );
    } else if (type === "relatedHandles") {
      setRelatedHandles((prevHandles) =>
        prevHandles.map((h, i) => (i === index ? { handle, platform } : h))
      );
    }
  };

  const handleDownload = (content) => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `file.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleCopy = (content) => {
    console.log("content: ", content);
    navigator.clipboard
      .writeText(content)
      .then(() => {
        // alert('Content copied to clipboard!');
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <div className="flex min-h-screen bg-[#FCFCFC] font-['Inter',sans-serif]">
      <Sidebar   balance={currentBalance}/>
      <div className="flex-1 p-6 h-screen overflow-y-auto ml-[360px] scrollbar-hidden">
        {showErrorPopup && (
          <ErrorPopup
            message={errorPopupConfig.message}
            link={errorPopupConfig.link}
          />
        )}
        <div className="max-w-6xl mx-auto">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold font-['Cormorant_Garamond',serif]">
              Concept Generation
            </h1>
            <Tooltip
              content="Generate content ideas based on your handles"
              userGuidePath="www.example.com/user-guide"
            />
          </div>

          <div className="flex gap-6">
            <div className="flex-1 bg-white rounded-lg space-y-6 p-5">
              {/* <div className="space-y-2">
                <HandleInputs 
                  label="Your handles" 
                  handles={handles} 
                  addHandle={(handle, platform) => handleAddHandle('handles', handle, platform)} 
                  removeHandle={(index) => handleRemoveHandle('handles', index)} 
                  updateHandle={(index, handle, platform) => handleUpdateHandle('handles', index, handle, platform)}
                  isEditing={true}
                />
              </div>

              <div className="space-y-2">
                <HandleInputs 
                  label="Related handles" 
                  handles={relatedHandles} 
                  addHandle={(handle, platform) => handleAddHandle('relatedHandles', handle, platform)} 
                  removeHandle={(index) => handleRemoveHandle('relatedHandles', index)} 
                  updateHandle={(index, handle, platform) => handleUpdateHandle('relatedHandles', index, handle, platform)}
                  isEditing={true}
                />
              </div> */}

              <div className="mb-6">
                <div className="flex items-center justify-between mb-2">
                  <label className="text-sm font-medium text-gray-700">
                    Additional Instructions{" "}
                    <span className="text-purple-400">(optional)</span>
                    <Tooltip
                      content="Additional Instructions"
                      label="Additional Instructions"
                      section="concept_generation"
                    />
                  </label>
                  <span className="text-sm text-gray-400">
                    {additionalInstructions.split(" ").length}/250 words
                  </span>
                </div>
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                  rows="4"
                  placeholder="e.g. Come up with 3 practical tips per concept"
                  value={additionalInstructions}
                  onChange={(e) => setadditionalInstructions(e.target.value)}
                ></textarea>
              </div>

              {!(
                concepts.mainstream.length > 0 || concepts.offstream.length > 0
              ) && (
                // <Button
                //   onClick={handleGenerateConcept}
                //   className="w-full bg-purple-600 rounded-full hover:bg-purple-700 text-white"
                //   disabled={isGenerating}
                // >
                //   {isGenerating
                //     ? "Generating..."
                //     : `Generate 5 Concept $${
                //         costs["concepts-generation"]?.toFixed(2) || "0.10"
                //       }`}
                // </Button>
                <Button
                  onClick={handleGenerateConcept}
                  className="w-full bg-purple-600 rounded-full hover:bg-purple-700 text-white transition duration-300 flex items-center justify-center"
                  disabled={isGenerating}
                >
                  {isGenerating ? (
                    <div className="flex items-center gap-2">
                      {/* Circular Loader */}
                      Generating...
                      <div className="w-5 h-5 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
                    </div>
                  ) : (
                    `Generate 5 Concept $${
                      costs["concepts-generation"]?.toFixed(2) || "0.10"
                    }`
                  )}
                </Button>
              )}

              {/* {error && <p className="text-red-500 text-sm">{error}</p>} */}

              {concepts.mainstream.length > 0 ||
              concepts.offstream.length > 0 ? (
                <div className="mt-4 h-[403px] overflow-y-auto">
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex space-x-4">
                      <span
                        className={`cursor-pointer ${
                          activeTab === "mainstream"
                            ? "text-blue-600 border-b-2 border-blue-600"
                            : "text-gray-400"
                        }`}
                        onClick={() => setActiveTab("mainstream")}
                      >
                        Mainstream
                      </span>
                      <span
                        className={`cursor-pointer ${
                          activeTab === "offstream"
                            ? "text-blue-600 border-b-2 border-blue-600"
                            : "text-gray-400"
                        }`}
                        onClick={() => setActiveTab("offstream")}
                      >
                        Offstream
                      </span>
                    </div>
                    <Tooltip
                      content="Mainstream concepts are more general, while offstream concepts are more niche"
                      userGuidePath="www.example.com/user-guide"
                    />
                  </div>
                  {concepts[activeTab].map((concept, index) => (
                    <div
                      key={index}
                      className={`p-2 mb-2 rounded cursor-pointer ${
                        selectedConcept === concept ? "bg-gray-100" : ""
                      }`}
                      onClick={() => setSelectedConcept(concept)}
                    >
                      {concept.concept.Title}
                    </div>
                  ))}
                </div>
              ) : loading ? (
                <div className="w-full h-[403px]">
                  <div className="flex justify-between items-center mb-2">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 mt-20 border-gray-900 mx-auto"></div>
                  </div>
                </div>
              ) : (
                <div className="w-full h-[403px]">
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex space-x-4">
                      <span
                        className={`cursor-pointer ${
                          activeTab === "mainstream"
                            ? "text-blue-600 border-b-2 border-blue-600"
                            : "text-gray-400"
                        }`}
                        onClick={() => setActiveTab("mainstream")}
                      >
                        Mainstream
                      </span>
                      <span
                        className={`cursor-pointer ${
                          activeTab === "offstream"
                            ? "text-blue-600 border-b-2 border-blue-600"
                            : "text-gray-400"
                        }`}
                        onClick={() => setActiveTab("offstream")}
                      >
                        Offstream
                      </span>
                    </div>
                    <Tooltip
                      content="Mainstream concepts are more general, while offstream concepts are more niche"
                      userGuidePath="www.example.com/user-guide"
                    />
                  </div>
                  <div className="flex flex-col h-full justify-center items-center">
                    <img
                      src="/No-Results.png"
                      alt=""
                      className="w-[140px] h-[113px]"
                    />
                    <p>Nothing here yet</p>
                  </div>
                </div>
              )}

              {(concepts.mainstream.length > 0 ||
                concepts.offstream.length > 0) && (
                  <Button
                  onClick={handleGenerateConcept}
                  className="w-full bg-purple-600 rounded-full hover:bg-purple-700 text-white transition duration-300 flex items-center justify-center"
                  disabled={isGenerating}
                >
                  {isGenerating ? (
                    <div className="flex items-center gap-2">
                      {/* Circular Loader */}
                      Generating...
                      <div className="w-5 h-5 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
                    </div>
                  ) : (
                    `Generate 5 Concept $${
                      costs["concepts-generation"]?.toFixed(2) || "0.10"
                    }`
                  )}
                </Button>
              )}
            </div>

            <div className="flex-[2] bg-white rounded-lg overflow-hidden">
              {/* {!selectedConcept && !isGenerating ? ( */}
              {!selectedConcept &&  (

                <div className="flex flex-col items-center justify-center h-full text-gray-400">
                  <img
                    src="/bulb.png"
                    alt="Concept"
                    className="mb-4 w-[109px] h-[119px]"
                  />
                  <p>Click on concept to view</p>
                </div>
              ) }

              {/* // : (
              //   isGenerating && (
              //     <div className="flex items-center justify-between bg-gray-200 rounded-md animate-pulse h-full w-full">
              //       <div className="flex flex-col w-full h-full justify-between flex-grow p-4">
              //         <div className="h-10 bg-gray-400 rounded-md animate-pulse w-1/4 "></div>
              //         <div className="h-24 bg-gray-300 border border-1 border-gray-500 rounded-md animate-pulse w-[80%] "></div>
              //         <div className="h-10 bg-gray-400 rounded-md animate-pulse w-1/4"></div>
              //         <div className="h-24 bg-gray-300 border border-1 border-gray-500 rounded-md animate-pulse w-[80%]"></div>
              //         <div className="h-10 bg-gray-400 rounded-md animate-pulse w-1/4"></div>
              //         <div className="h-24 bg-gray-300 border border-1 border-gray-500 rounded-md animate-pulse w-[80%]"></div>
              //         <div className="h-10 bg-gray-400 rounded-md animate-pulse w-1/4"></div>
              //         <div className="h-24 bg-gray-300 border border-1 border-gray-500  rounded-md animate-pulse w-[80%]"></div>
              //       </div>
              //     </div>
              //   )
              // )} */}
              {selectedConcept && (
                <div className="h-full flex flex-col overflow-y-scroll scrollbar-hidden">
                  <div className="bg-gray-50 px-6 py-4 flex justify-between items-center border-b border-gray-200">
                    <h2 className="text-xl font-semibold">
                      {selectedConcept.concept.Title}
                    </h2>
                    <div className="flex space-x-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() =>
                          handleDownload(`
                          Concept:
                          ${selectedConcept.concept.Concept}

                          Details:
                          ${selectedConcept.concept.Details}

                          Practical Tips you can add to the post:
                          ${selectedConcept.concept["Practical Tips"]}

                          Inspiration for media you can add to the post:
                          ${selectedConcept.concept["Media Inspiration"]}
                            `)
                        }
                      >
                        <Download size={16} />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() =>
                          handleCopy(`
                          Concept:
                          ${selectedConcept.concept.Concept}

                          Details:
                          ${selectedConcept.concept.Details}

                          Practical Tips you can add to the post:
                          ${selectedConcept.concept["Practical Tips"]}

                          Inspiration for media you can add to the post:
                          ${selectedConcept.concept["Media Inspiration"]}
                            `)
                        }
                      >
                        <Copy size={16} />
                      </Button>
                      <Button variant="ghost" size="icon">
                        <X size={16} onClick={() => setSelectedConcept(null)} />
                      </Button>
                    </div>
                  </div>

                  <div className="flex-1 overflow-y-auto p-6 space-y-6 rounded-lg">
                    <div className="bg-[#FCFCFC] p-4 rounded-lg">
                      <div className="flex justify-between items-start bg-[#FCFCFC] p-4 rounded-lg">
                        <h3 className="text-lg -ml-4 font-semibold text-gray-700">
                          Concept
                        </h3>
                        {/* <Button 
                          onClick={handleGeneratePost} 
                          className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full"
                          disabled={isGeneratingPost}
                          disabled={true}
                        >
                          {isGeneratingPost ? 'Generating...' : `Draft Post $${costs.posts?.toFixed(2) || '0.10'}`}
                          Comming Soon
                        </Button> */}
                      </div>
                      {!isPostModalOpen && (
                        <p className="text-gray-600 bg-white p-4 rounded-lg">
                          {selectedConcept.concept.Concept}
                        </p>
                      )}
                    </div>

                    {isPostModalOpen ? (
                      <div className="bg-[#FCFCFC] p-4 rounded-lg mb-6">
                        <div className="space-y-4">
                          <div
                            className="bg-purple-100 p-4 rounded-lg cursor-pointer"
                            onClick={() => setIsPostModalOpen(false)}
                          >
                            <div className="flex justify-between items-center">
                              <div className="flex gap-4">
                                <span className="font-semibold text-purple-700">
                                  Generated Posts
                                </span>
                                <span className="text-purple-700">
                                  {generatedPosts.length}
                                </span>
                              </div>
                              <ChevronDown className="text-purple-700" />
                            </div>
                          </div>
                          {generatedPosts?.map((post, index) => (
                            <div
                              key={post.id}
                              className="bg-white p-4 rounded-lg"
                            >
                              <h4 className="font-semibold mb-2">
                                {post.title}
                              </h4>
                              <p className="text-gray-600">{post.content}</p>
                              <div className="flex justify-start mt-2 space-x-2">
                                <button
                                  onClick={() => {
                                    /* Implement download functionality */
                                  }}
                                  className="text-gray-600 hover:text-gray-900 border p-1 rounded-lg transition-colors duration-200"
                                >
                                  <Download size={16} />
                                </button>
                                <button
                                  // onClick={()=>handleCopy()}
                                  className="text-gray-600 hover:text-gray-900 border p-1 rounded-lg transition-colors duration-200"
                                >
                                  <Copy size={16} />
                                </button>
                                <button
                                  onClick={() =>
                                    handleSavePost(post.title, post.content)
                                  }
                                  className="text-gray-600 hover:text-gray-900 border p-1 rounded-lg transition-colors duration-200"
                                >
                                  <Save size={16} />
                                </button>
                                <button
                                  onClick={() => {
                                    /* Implement delete functionality */
                                  }}
                                  className="text-gray-600 hover:text-gray-900 border p-1 rounded-lg transition-colors duration-200"
                                >
                                  <Trash2 size={16} />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* <div className='bg-[#FCFCFC] p-4 rounded-lg'>
                          <p className="text-gray-600 bg-white p-4 rounded-lg">{selectedConcept.concept.Concept}</p>
                        </div> */}

                        <div className="bg-[#FCFCFC] p-4 rounded-lg">
                          <h3 className="text-lg font-semibold text-gray-700 mb-2">
                            Details
                          </h3>
                          <p className="text-gray-600 bg-white p-4 rounded-lg">
                            {selectedConcept.concept.Details}
                          </p>
                        </div>

                        <div className="bg-[#FCFCFC] p-4 rounded-lg">
                          <h3 className="text-lg font-semibold text-gray-700 mb-2">
                            Practical Tips you can add to the post
                          </h3>
                          <p className="text-gray-600 bg-white p-4 rounded-lg">
                            {selectedConcept.concept["Practical Tips"]}
                          </p>
                        </div>

                        <div className="bg-[#FCFCFC] p-4 rounded-lg">
                          <h3 className="text-lg font-semibold text-gray-700 mb-2">
                            Inspiration for media you can add to the post
                          </h3>
                          <p className="text-gray-600 bg-white p-4 rounded-lg">
                            {selectedConcept.concept["Media Inspiration"]}
                          </p>
                        </div>

                        {generatedPosts.length > 0 && (
                          <div
                            className="bg-purple-100 p-4 rounded-lg cursor-pointer"
                            onClick={() => setIsPostModalOpen(!isPostModalOpen)}
                          >
                            <div className="flex justify-between items-center">
                              <div className="flex gap-4">
                                <span className="font-semibold text-purple-700">
                                  Generated Posts
                                </span>
                                <span className="text-purple-700">
                                  {generatedPosts.length}
                                </span>
                              </div>
                              <ChevronUp className="text-purple-700" />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
