import React, { useState } from 'react';
import axios from 'axios';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { ArrowLeft, User, Mail, Rocket, HelpCircle } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { signInWithGooglePopup,auth } from "../utils/firebase.utils"
import { signInWithCustomToken } from 'firebase/auth';

export default function Signup() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();


  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://backend-individuals.fluffy-ai.com/api/user/authenticate/signup', {
        email: email
      });
      if (response.status === 200) {
        setStep(2);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post('https://backend-individuals.fluffy-ai.com/api/user/authenticate/signup/verify-otp', {
        email: email,
        otp: verificationCode.join('')
      });
      if (response.status === 200) {

        console.log(response.data)
        setIsLoading(true);
        

        try {
          setIsLoading(true);
          const userCredential = await signInWithCustomToken(auth, response.data['custom-token']);
          console.log("Login successful. UserCredential object:", userCredential);

          // Get the ID token from the user
          const idToken = await userCredential.user.getIdToken();
          console.log("ID Token:", idToken);
          // const idToken = response.data['custom-token']
      
          if (!idToken) {
            console.error("No ID token received.");
            return;
          }
      
      
          setTimeout(async () => {
            try {
              setIsLoading(true);
              const apiResponse = await axios.post("https://backend-individuals.fluffy-ai.com/api/user/create-session", 
                { id_token: idToken }, // Payload with the token
                {
                  headers: {
                    "Content-Type": "application/json"
                  },
                 withCredentials: true
                }
              );
              // Extract the session cookie from the response headers
            const sessionCookie = apiResponse.headers
            console.log("Session Cookie:", sessionCookie);
            // navigate("/dashboard");
            // await login(email);
            setStep(3)
            setIsLoading(false);
            } catch (apiError) {
              console.error("API error:", apiError.response ? apiError.response.data : apiError.message);
            }
          }, 2000);
      
        } catch (error) {
          console.error("Error signing in with Google:", error.message);
        }

        
      }

      console.log("status not ok")
    } catch (err) {
      setError(err.response?.data?.message || 'Invalid OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };


  const goToDashBoard = async()=>{
    await login(email);
  }

  const handleVerificationInput = (index, value) => {
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);
    if (value && index < 3) {
      document.getElementById(`code-${index + 1}`)?.focus();
    }
  };






  const signInWithGoogle = async () => {
    setIsLoading(true);
    try {
      const response = await signInWithGooglePopup();
      const idToken = response.user.getIdToken ? await response.user.getIdToken() : null;
  
      if (!idToken) {
        console.error("No ID token received.");
        return;
      }
  
      // Log the ID token to ensure it’s working
      console.log("Google ID Token:", idToken);
  
      setTimeout(async () => {
        try {
          setIsLoading(true);
          const apiResponse = await axios.post("https://backend-individuals.fluffy-ai.com/api/user/create-session", 
            { id_token: idToken }, // Payload with the token
            {
              headers: {
                "Content-Type": "application/json"
              },
             withCredentials: true
            }
          );
          // Extract the session cookie from the response headers
        const sessionCookie = apiResponse.headers
        console.log("Session Cookie:", sessionCookie);
        // navigate("/dashboard");
        await login(email); 
        setIsLoading(false);
        } catch (apiError) {
          setIsLoading(false);
          console.error("API error:", apiError.response ? apiError.response.data : apiError.message);
        }
      }, 2000);
  
    } catch (error) {
      setIsLoading(false);
      console.error("Error signing in with Google:", error.message);
    }
  };



  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');

    // Split clipboard data into an array of up to 4 characters
    const chunks = pastedData.slice(0, 4).split('');

    // Update the verificationCode state with the chunks
    setVerificationCode((prev) => prev.map((_, index) => chunks[index] || ''));
  };








  return (
    <div className="flex min-h-screen bg-white font-['Inter',sans-serif]">
      <div className="w-1/3 bg-[#112863] p-8 text-white">
        <div className="flex items-center mb-12">
          <div className="w-10 h-10 bg-white rounded-md flex items-center justify-center mr-3">
            <span className="text-[#1E2A4A] font-bold text-2xl font-['Cormorant_Garamond',serif]"><img src="/favicon.png" className='w-[30px] h-[30px]' alt="Fluffy logo" /></span>
          </div>
          <span className="text-2xl font-semibold font-['Cormorant_Garamond',serif]">Fluffy</span>
        </div>
        {/* <Link to="/" className="flex items-center text-sm mb-16 font-['Cormorant_Garamond',serif] text-gray-300 hover:text-white transition-colors">
          <ArrowLeft className="mr-2" size={18} />
          Back to home
        </Link> */}

        <div className="flex items-center text-sm mb-16 font-['Cormorant_Garamond',serif] text-gray-300 hover:text-white transition-colors">
          <ArrowLeft className="mr-2" size={18} />
          Back to home
        </div>
        <div className="space-y-8 relative">
          <div className="absolute left-4 top-8 bottom-8 w-px bg-gray-600" />
          <div className={`${step===1 ? 'border bg-white p-[10px] text-black':'border border-[#FFFFFF4D] p-[10px] bg-[#112863]'} rounded-[8px] flex items-center relative z-10`}>
            <div className={`w-8 h-8 rounded-[5px] ${step === 1 ? 'bg-gradient-to-r from-[#FFB2A1] to-[#9747FF] p-[1px]' : 'bg-transparent border-2 border-white'} flex items-center justify-center mr-4`}>
              <div className={`${step === 1 ? 'bg-white': 'bg-[#112863] '  } w-full h-full rounded-[4px] flex items-center justify-center`}>
                <User className={step === 1 ? 'text-[#1E2A4A]' : 'text-white'} size={16} />
              </div>
            </div>

            <div>
              <p className="font-semibold font-['Cormorant_Garamond',serif]">Your details</p>
              <p className="text-sm text-gray-400 mt-2">Provide your email address</p>
            </div>
          </div>
          <div className={`${step===2 ? 'border bg-white p-[10px] text-black': 'border border-[#FFFFFF4D] p-[10px] bg-[#112863]'} rounded-[8px] flex items-center relative z-10`}>
            <div className={`w-8 h-8 rounded-[5px] ${step === 2 ? 'bg-gradient-to-r from-[#FFB2A1] to-[#9747FF] p-[1px]' : 'bg-transparent border-2 border-white'} flex items-center justify-center mr-4`}>
              <div className={`${step === 2 ? 'bg-white ': 'bg-[#112863]'  } w-full h-full rounded-[4px] flex items-center justify-center`}>
                <Mail className={step === 2 ? 'text-[#1E2A4A]' : 'text-white'} size={16} />
              </div>
            </div>
            <div>
              <p className="font-semibold font-['Cormorant_Garamond',serif]">Verify your email</p>
              <p className="text-sm text-gray-400 mt-2">Verify the provided email address</p>
            </div>
          </div>
          <div className={`${step===3 ? 'border bg-white p-[10px] text-black': 'border border-[#FFFFFF4D] p-[10px] bg-[#112863]'}  rounded-[8px] flex items-center relative z-10`}>
            <div className={`w-8 h-8 rounded-[5px] ${step === 3 ? 'bg-gradient-to-r from-[#FFB2A1] to-[#9747FF] p-[1px]' : 'bg-transparent border-2 border-white'} flex items-center justify-center mr-4`}>
              <div className={`${step === 3 ? 'bg-white': 'bg-[#112863]'  } w-full h-full rounded-[4px] flex items-center justify-center`}>
                <Rocket className={step === 3 ? 'text-[#1E2A4A]' : 'text-white'} size={16} />
              </div>
            </div>
            <div>
              <p className="font-semibold font-['Cormorant_Garamond',serif]">Welcome to Fluffy!</p>
              <p className="text-sm text-gray-400 mt-2">Get running in few minutes!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-2/3 p-8 flex items-center justify-center">
        <div className="w-full max-w-md text-center">
          {step === 1 && (
            <>
              <h1 className="leading-[33.6px] mb-2 text-[28px] font-[700] mt-5 font-['Cormorant_Garamond',serif]">Create a Free Account</h1>
              <p className="text-[18px] font-[400] text-[#676767] mb-10">Provide your email address to continue</p>
              <div >
                <div className="mb-4">
                  <Label htmlFor="email" className="flex items-center mb-1">
                    Email
                    <HelpCircle className="ml-1 text-gray-400" size={16} />
                  </Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border-gray-300 h-[36px] max-w-[454px]" />
                  {error && <p className="text-red-500 text-left text-sm mt-1">{error}</p>}
                </div>
                <Button
                  type="submit"
                  className="w-[80%] h-[59px] text-[16px] mt-3 bg-[#2D5BFF] hover:bg-blue-600 text-white rounded-full py-2"
                  disabled={isLoading}
                  onClick={handleEmailSubmit}
                >
                  {isLoading ? 'Loading...' : 'Continue'}
                </Button>
              </div>
              <div className="mt-6 text-center">
                <div className="text-center relative mb-[80px] mt-[80px]">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative"> 
                    <span className="px-2 bg-white text-sm text-gray-500">OR</span>
                  </div>
                </div>
                <button onClick={signInWithGoogle} className="flex items-center justify-center w-full max-w-[454px] h-[60px] mb-3 py-2 border border-gray-300 rounded-full text-sm font-medium text-gray-700 hover:bg-gray-50" disabled={isLoading}>
                  <img src="google.png" alt="Google" className="w-5 h-5 mr-2" />
                  {isLoading ? 'Loading...' : 'Continue with Google'}
                </button>
                <button disabled={isLoading} className="flex items-center justify-center w-full max-w-[454px] h-[60px] py-2 border border-gray-300 rounded-full text-sm font-medium text-gray-700 hover:bg-gray-50">
                  <img src="apple.png" alt="Apple" className="w-5 h-5 mr-2" />
                  {isLoading ? 'Loading...' : 'Continue with Apple'}
                </button>
              </div>
              <p className="text-sm text-center mt-10">
                Already have an account? <Link to="/login" className="text-[#2D5BFF] hover:underline">Login</Link>
              </p>
            </>
          )}
          {step === 2 && (
            <>
              <h1 className="text-3xl font-bold mb-2 font-['Cormorant_Garamond',serif]">Verify your Email</h1>
              <p className="text-gray-600 mb-6">We sent a code to {email}</p>
              <form onSubmit={handleVerificationSubmit}>
                <div className="flex justify-between mb-12 px-[20%] mt-12">
                  {verificationCode.map((digit, index) => (
                    <Input
                      key={index}
                      id={`code-${index}`}
                      type="text"
                      maxLength={1}
                      className={`w-12 h-12 text-center text-2xl border-gray-300 rounded-lg ${
                        digit ? 'bg-[#EAEFFD] text-[#1D44A8]' : 'bg-white'
                      }`}
                      value={digit}
                      onChange={(e) => handleVerificationInput(index, e.target.value)}
                      onPaste={index === 0 ? handlePaste : undefined}
                    />
                  ))}
                </div>
                {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                <Button
                  type="submit"
                  className="w-[80%] bg-[#2D5BFF] hover:bg-blue-600 text-white rounded-full py-2"
                  disabled={isLoading}
                >
                  {isLoading ? 'Verifying...' : 'Continue'}
                </Button>
              </form>
              <div className="mt-6 text-center">
                <a href="#" className="text-sm text-[#2D5BFF] hover:underline">Didn't get a code? Resend</a>
              </div>
              <div className="mt-2 text-center">
                <a href="#" className="text-sm text-gray-600 hover:underline">Change email address</a>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <h1 className="text-3xl font-bold mb-4 font-['Cormorant_Garamond',serif]">Welcome to Fluffy!🎉</h1>
              <p className="text-gray-600 mb-8">Thank you for choosing Fluffy! We're excited to help you create engaging content that resonates with your audience.</p>
              <Button
                onClick={goToDashBoard}
                disabled={isLoading}
                className="w-full max-w-[401px] text-[16px] font-[400] h-[59px] bg-[#2960EC] hover:bg-blue-600 text-white rounded-full py-3 text-lg "
              >
                {isLoading ? 'Loading...' : 'Continue'}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}